import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import axios from 'axios';
import SnackBar from '../Components/Snackbar';
import Navigation from '../Components/Navigation'
import SecondFooter from '../Components/SecondFooter';
import implemenitLogo from '../Assets/ImplementIT_logo.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { login } from '../API/API_Service';
const theme = createTheme();

export default function AdminLogin() {

    const navigate = useNavigate();
      const [open, setOpen] = useState(false);
      const [message, setMessage] = useState("");
      const [status, setStatus] = React.useState();
      const [color, setColor] = useState();
      const[email, setEmail] = useState('');
      const[password, setPassword]= useState('');
      const [errMsg , setErrMsg] = useState('');
      const [rememberMe, setRememberMe] = useState(false);

      
      const handleSubmit = (e) => {
        e.preventDefault();
        if(email !== "" && password !== ""){
        const serverData = new FormData()
        serverData.append('email', email);
        serverData.append('userPassword',password);
        axios({
          method: "POST",
          url:login,
          data: serverData,
      }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
                setErrMsg(res.data.message)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setErrMsg(res.data.message)
                localStorage.setItem('ImpAdminauth', true);
                navigate(`layout`)
            }
        }).catch(err => {
            console.log(err)
        });
      }
      else{
          setErrMsg('Please Enter Email and Password')
      }
      }



  return (

    <Box  bgcolor='#292929' className='signinBanner' py={1}>
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />

    <Grid container display='flex' justifyContent='space-between' alignContent='center' alignItems='center'>

        <Grid item xs={12} sm={12} md={6} lg={6} display='flex' justifyContent='end'>
        <img src={implemenitLogo} style={{width:'80%' , height:200 , objectFit:'contain'}} alt='header' />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box py={2}>
        <Typography  component="h1" variant="h5" color='#fff'  textAlign='center'>Admin Login</Typography>
        </Box>
        <Container component="main"  maxWidth='xs'>
        <Box className='signinBox'> 
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}  width='100%'>
    <Stack textAlign='left' spacing={2} p={3}>
    <Typography component="h1" variant="h5"   textAlign='center' >
     Sign in
    </Typography>
    <Box>
    <Typography>Email</Typography>
    <TextField
    margin="normal"
    required
    fullWidth
    id="email"
    name="email"
    type='email'
    autoComplete="email"
    onChange={(e)=>setEmail(e.target.value)}
    />
    </Box>
    <Box>
    <Typography>Password</Typography>
    <TextField
    margin="normal"
    required
    fullWidth
    name="password"
    type="password"
    id="password"
    autoComplete="current-password"
    onChange={(e)=>setPassword(e.target.value)}
    helperText={errMsg}
    />
    </Box>
    <Box >
    <Button type='submit' onSubmit={handleSubmit} variant='outlined' fullWidth sx={{textTransform:'uppercase', color:'#fff' ,  borderRadius:'10px' , borderColor:'#fff'}}>SIGN IN <Button sx={{ml:2}} className='BlogButton'> <ArrowForwardIcon /></Button></Button>
      </Box>
    </Stack>
    </Box>
    </Box>

    </Container>
 
        </Grid>

    </Grid>

    </Box>
  );
}

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';

      
function App() {

  let theme = createTheme({
    typography: {
      h6: {
        color: '#fff', 
      },
      h4: {
        color:'#fff', 
      },
      h3: {
        color: '#fff',  
      },
      body1: {
        color:'#fff', 
      },
      subtitle1: {
        color:'#fff', 
      },
      subtitle2: {
        color:'#fff',
      },
    },
  });


  return (
   
  <ThemeProvider theme={theme}>
        <Router>
          <Routes />
        </Router>
      </ThemeProvider>

  

  );
}
export default App;

import React, { useState } from 'react'
import {  Navigate, useRoutes } from 'react-router-dom'
import HomePage from './Pages/HomePage';
import AboutPage from './Pages/AboutPage';
import ProductPage from './Pages/ProductPage';
import ServicePage from './Pages/ServicePage';
import ContactPage from './Pages/ContactPage';
import EnquiryData from './Pages/EnquiryData';
import AdminLogin from './Pages/AdminLogin';
import Layout from './Components/Layout';


// this is pages

// this variable is for starting url
//this variable is for nadi local development 
// export const startUrl = `/nadipms/`;

//this variable is for local development 
export const startUrl = `/`;

// this variable for testing server
// export const startUrl = `/nadi/testing/`;

// this variable is for live server
// export const startUrl = `/nadi/web/`;

function Routes() {
   const auth = localStorage.getItem('ImpAdminauth');
   const [successOpen, setsuccessOpen] = useState(false);
   const [successMessage, setsuccessMessage] = useState('');
   const [successStatus, setsuccessStatus] = useState(false);
   const [successColor, setsuccessColor] = useState(false);


    return useRoutes([
      {
      path: startUrl,
      element: <HomePage />
      },
      {
        path: '/home',
        element: <Navigate to={startUrl} />
        },
      {
        path:'/about',
        element: <AboutPage />,
      },
      {
        path:'/services',
        element: <ServicePage />,
      },
      {
        path:'/products',
        element: <ProductPage />,
      },
      {
        path:'/contact',
        element: <ContactPage />,
      },
      {
        path:'/admin',
        element: <AdminLogin />,
      },
      {
        path:'/admin/layout',
        element: auth ? <Layout /> :   <Navigate to='admin' />,
        children: [
          {
            path:'enquirydata',
            element: auth ? <EnquiryData /> :   <Navigate to='admin' />,
          },
        ]
      },
      {
        path:'/logout',
        element:  <Navigate to='/admin' />,
      },
    ])
}

export default Routes;
import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Container, Grid, Link, Stack, Typography } from '@mui/material'
import implemenitLogo from '../Assets/ImplementIT_Build-removebg-preview-removebg-preview.png';
import { useLocation, useNavigate } from 'react-router-dom';
import FacebookIcon from '../Assets/Footer/icons8-facebook-96.png' ;
import TwitterIcon from '../Assets/Footer/icons8-twitter-96.png' ;
import YouTubeIcon from '../Assets/Footer/icons8-youtube-96.png' ;
import LinkedInIcon from '../Assets/Footer/icons8-linkedin-96.png' ;
import InstagramIcon from '../Assets/Footer/icons8-insta-96.png' ;

export default function Footer() {

    const navigate = useNavigate();

  return (
    <Box  >

        <Box py={5}>
        <Grid container display='flex' justifyContent='space-evenly' p={1} spacing={{lg:0 , md:0, sm:5 , xs:5}}>

        <Grid item xs={12} sm={6} md={6} lg={3} display='flex' justifyContent={{lg:'end', md:'end' , sm:'end' , xs:'center'}}>
          <Stack spacing={1} textAlign='left'>
            <Link href="/" className="header__content__logo">
           <img src={implemenitLogo} style={{width:300, height:100 , objectFit:'contain'}} alt='header' />
            </Link>


          <Box display='flex' flexDirection='row' gap={3}>
          <a style={{color:'#2d2d2d'}} href="" target="_blank" rel="noopener noreferrer">
          <img src={YouTubeIcon} alt='YT' style={{height:30, width:30, marginRight:5,  objectFit:'contain',}} />
          </a>

          <a style={{color:'#2d2d2d'}} href="" target="_blank" rel="noopener noreferrer">
          <img src={FacebookIcon} alt="FB" style={{height:30, width:30, marginRight:5, objectFit:'contain'}} />
          </a> 

          <a style={{color:'#2d2d2d'}} href="" target="_blank" rel="noopener noreferrer">
          <img src={TwitterIcon} alt='tw' style={{height:30, width:30, marginRight:5, objectFit:'contain'}} />
          </a> 

          <a style={{color:'#2d2d2d'}} href="" target="_blank" rel="noopener noreferrer">
          <img src={LinkedInIcon} alt="ln" style={{height:30, width:30, marginRight:5, objectFit:'contain'}} />
          </a>  

          <a style={{color:'#2d2d2d'}} href="" target="_blank" rel="noopener noreferrer">
          <img src={InstagramIcon} alt='in' style={{height:30, width:30, marginRight:5, objectFit:'contain'}} />
          </a>    
          </Box>         
          </Stack>
          </Grid>      


          <Grid item xs={12} sm={6} md={6} lg={3} display='flex' justifyContent='center' textAlign={{lg:'left', md:'left', sm:'left', xs:'center'}}>
          <ul style={{listStyle:'none' , display:'flex' , flexDirection:'column', gap:'10px' }}>
           <Typography variant="h3" color="#C7854C" fontWeight={500} fontSize={21} marginBottom='20px'>Contact Us</Typography>
            <li><Typography fontSize={17} >Email</Typography></li>     
            <li><Typography fontSize={15} color='#798DA3'>support@ImplementIT.in</Typography></li>  
            <li><Typography fontSize={15} color='#798DA3'>www.ImplementIT.in</Typography></li>
            <li><Typography fontSize={17}>Address</Typography></li>                     
            <li><Typography fontSize={15} color='#798DA3'>F503, Aparna Hillpark Silver Oaks,</Typography></li>  
            <li><Typography fontSize={15} color='#798DA3'>ChandaNagar, Hyderabad</Typography></li> 
            <li></li>
            <li><Typography fontSize={15} color='#798DA3'>5-416, Main Road, Komarole,</Typography></li> 
            <li><Typography fontSize={15} color='#798DA3'>Prakasam District, AndhraPradesh</Typography></li> 
            </ul>
          </Grid>    


          <Grid item xs={12} sm={6} md={6} lg={3} display='flex' justifyContent='center ' textAlign={{lg:'left', md:'left', sm:'left', xs:'center'}}>
          <ul style={{listStyle:'none' , display:'flex' , flexDirection:'column', gap:'10px' }}>
          <Typography variant="h3" color="#C7854C" fontWeight={500} fontSize={21}  marginBottom='20px'>Support</Typography>
          <li><Link onClick={()=>navigate('/')} underline="none" color="#ffffff" sx={{cursor:'pointer'}}><Typography fontSize={17}>Terms Of Use</Typography></Link></li>
          <li><Link onClick={()=>navigate('/')}  underline="none" color="#ffffff" sx={{cursor:'pointer'}}><Typography fontSize={17}>Privacy</Typography></Link></li>           
          <li><Link onClick={()=>navigate('/contact')}  underline="none" color="#ffffff" sx={{cursor:'pointer'}}><Typography fontSize={17}>Contact</Typography></Link></li>           
          <li><Link  onClick={()=>navigate('/')} underline="none" color="#ffffff" sx={{cursor:'pointer'}}><Typography fontSize={17}>FAQ</Typography></Link></li>           
          </ul>

          </Grid> 


          <Grid item xs={12} sm={6} md={6} lg={3} display='flex' justifyContent='center' textAlign={{lg:'left', md:'left', sm:'left', xs:'center'}}>
          <ul style={{listStyle:'none' , display:'flex' , flexDirection:'column', gap:'10px' }}>
          <Typography variant="h3" color="#C7854C" fontWeight={500} fontSize={21}  marginBottom='20px'>Quick Links</Typography>
          <li><Link onClick={()=>navigate('/')}  underline="none" color="#ffffff" sx={{cursor:'pointer'}}><Typography fontSize={17}>Home</Typography></Link></li>
          <li><Link onClick={()=>navigate('/services')} underline="none" color="#ffffff" sx={{cursor:'pointer'}}><Typography fontSize={17}>Services</Typography></Link></li>            
          <li><Link onClick={()=>navigate('/products')}  underline="none" color="#ffffff" sx={{cursor:'pointer'}}><Typography fontSize={17}>Products</Typography></Link></li>            
          <li><Link onClick={()=>navigate('/about')} underline="none" color="#ffffff" sx={{cursor:'pointer'}}><Typography fontSize={17}>About Us</Typography></Link></li>                       
          </ul>
          </Grid> 
          </Grid>      


          </Box>
    
        
   

        <Grid container mt={{lg:5 , md:5, sm:3 , xs:2}} p={1} spacing={1}>
        <Grid item xs={12}  md={6} textAlign='center' justifyContent='center' display='flex'>
        <Typography className='copyright'>
        © ImplementIT {new Date().getFullYear()}. All Rights Reserved.
        </Typography>
        </Grid>
        <Grid item xs={12} md={6} textAlign='center' px={2} justifyContent={{lg:'end', md:'end', sm:'center', xs:'center'}} display='flex'>
        <Link href='https://www.mdqualityapps.com/' underline="none" target="_blank" rel="noopener">
            <Typography sx={{color:"#C7854C"}}> Designed by  MDQuality Apps Solutions</Typography>
            </Link>

              <a
              href="https://api.whatsapp.com/send?phone=+916301292546&text=Hi!"
              target="_blank"
              className="float"
              style={{ bottom: '70px' }}
              rel="noopener noreferrer" // Add noreferrer for security
              >
              <img
              width="70"
              height="70"
              src="https://img.icons8.com/color/200/whatsapp--v1.png"
              alt="whatsapp--v1"
              />
              </a>

        </Grid>

        </Grid>

</Box>
  )
}

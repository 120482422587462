const apibaseUrl = `https://mdqualityapps.in/API/implement_it/development/`;


const methodGet = 'GET';
const methodPost = 'POST';



const login = `${apibaseUrl}login`;

const add_query = `${apibaseUrl}add_query`;

const get_query = `${apibaseUrl}get_query`;



export { methodGet, methodPost, login , add_query , get_query}
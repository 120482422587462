import Navigation from '../Components/Navigation'
import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography, Container, Grid, Card, CardMedia, CardContent, Button, Link, List, ListItem, ListItemIcon, ListItemText, Divider, Accordion, AccordionActions, AccordionDetails, AccordionSummary, Stack, } from '@mui/material'
import Footer from '../Components/Footer';
import Image1 from '../Assets/Our Vision.jpg';
import Image2 from '../Assets/Our Mission.jpg';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useNavigate } from 'react-router-dom';
import { AboutValues, aboutContentList } from '../Variables/Variables';
import FiberManualRecordSharpIcon from '@mui/icons-material/FiberManualRecordSharp';

export default function AboutPage() {

  const navigate = useNavigate();

  useEffect(() => {
    const slideCategoryId = window.location.hash.substring(1);

    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  }, []);

  return (
    <Box>
    <Box sx={{ backgroundColor: '#292929' }}>
        <Navigation />

        <Container>
        <Box py={5} position='relative'>
          <Grid container >
            <Grid item xs={12} md={12} lg={12} xl={12} className='aboutsection1'>
              <Box display='flex' flexDirection='column' py={2}>
                <Typography variant="h4" textAlign='center' fontWeight={600} mb={3}>The ImplementIT Story</Typography>
                <Typography textAlign='justify' variant='h6'>At ImplementIT, we believe there is a better way to do marketing. A more valuable way where customers are earned rather than bought. We're obsessively passionate about it, and our mission is to help people achieve it. We focus on search engine optimization. It's one of the least understood and least transparent aspects of great marketing, and we see that as an opportunity. We're excited to simplify SAP for everyone through our software, education, and community.</Typography>

              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Box>
          <Grid container position='relative'>

          <Grid py={8} px={1} className='aboutgrid' bgcolor='#dfeeee' item xs={12} sm={12} lg={6} xl={6} md={6} display='flex' alignItems='center' order={{ xs: 2, sm: 2, lg: 1 }}>
            <Box px={3} display='flex' flexDirection='column' gap={3}>
              <Typography variant="h4" fontWeight={600} color='#C7854C' textAlign='center'>Our Vision</Typography>
              <Typography textAlign='justify' variant="subtitle1" sx={{ marginTop: 1, color: '#49484a' }}>To be a leading service provider in the ERP/SAP ecosystem by supporting organizations to achieve their objectives.
</Typography>
              </Box>
            </Grid>

          <Grid py={8} px={1}  bgcolor='#dfeeee' item xs={12} sm={12} lg={6} xl={6} md={6} display='flex' justifyContent='center' alignItems='center' order={{ xs: 1, sm: 1, lg: 2 }}>
              <img src={Image1} alt="img" style={{ objectFit: 'cover', width: '100%', height: 300}} />
            </Grid>

          <Grid py={8} px={1} className='aboutgrid1' bgcolor='#f0f7f7' item xs={12} sm={12} lg={6} xl={6} md={6} display='flex' justifyContent='center' alignItems='center' order={{ xs: 3, sm: 3, lg: 3 }}>
            <img src={Image2} alt="img" style={{ objectFit: 'cover', width: '100%', height: 300 }} />
            </Grid>

          <Grid py={8} px={1} className='aboutgrid2' bgcolor='#f0f7f7' item xs={12} sm={12} lg={6} xl={6} md={6} display='flex' alignItems='center' order={{ xs: 4, sm: 4, lg: 4 }}>
            <Box px={3} display='flex' flexDirection='column' gap={3}>
              <Typography variant="h4" fontWeight={600} color='#C7854C' textAlign='center'>Our Mission</Typography>
              <Typography textAlign='justify' variant="subtitle1" sx={{ marginTop: 1, color: '#49484a' }}>To build a global team capable of delivering high quality and cost efficient innovative solutions to help customers achieve their business objectives by providing intelligent, best in class consulting, IT solutions and services.
</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>


        <Box>
        <Grid container position='relative'>

        <Grid py={8} px={1} className='aboutgrid4' bgcolor='#dfeeee' item xs={12}>
        <Box textAlign='center'>
        <Typography variant="h4" fontWeight={600} color='#C7854C' textAlign='center'>Our Values</Typography>
        <Typography variant="h6" fontWeight={600} textAlign='center'  sx={{ marginTop: 1.5, color: '#49484a' }}>We are committed to</Typography>
        </Box>
        <Box pt={4}>
        <Container>
        <Grid container spacing={3}>
        {
         AboutValues?.map((i)=>{
        return(
        <Grid item xs={12} sm={12} md={6} lg={6}  textAlign='left'>
        <Box display='flex' flexDirection='row' gap={1}>
        <FiberManualRecordSharpIcon sx={{verticalAlign:'middle', color:'#B56A30'}} /> 
        <Typography sx={{color: '#49484a' }}>{i.value}</Typography>
        </Box>
        </Grid>
        )
        })
        }
        </Grid>
        </Container>
        </Box>
        </Grid>
        </Grid>
        </Box>


      <Box position='relative'>
      <Stack spacing={3} p={6} className='aboutgrid3'  textAlign='center' backgroundColor='#010417'>
      <Typography variant='h3'>Interested in our latest chapter?</Typography>
      <Box>
      <Button onClick={()=>navigate('/products')} variant='outlined' sx={{ color:'#fff' ,  borderRadius:'10px' , borderColor:'#fff'}}>Learn More About our Products <DoubleArrowIcon sx={{verticalAlign:'middle' , ml:2}} /> </Button>
      </Box>
      </Stack>
      </Box>

       <Footer />


        </Box>
        </Box>
  )
}

import NewsIMage from '../Assets/Overlap Image.jpg';

import MediaClient1 from '../Assets/Clients/AlBathaTrading_logo-removebg-preview.png';
import MediaClient2 from '../Assets/Clients/Beigene_logo-removebg-preview.png';
import MediaClient3 from '../Assets/Clients/Finesse-Logo-removebg-preview.png';
import MediaClient4 from '../Assets/Clients/JayTechLLC-removebg-preview.png';
import MediaClient5 from '../Assets/Clients/MeirTobler_logo-removebg-preview.png';
import MediaClient6 from '../Assets/Clients/RAKEZ_logo-removebg-preview.png';
import MediaClient7 from '../Assets/Clients/REDCommerceInc-removebg-preview.png';
import MediaClient8 from '../Assets/Clients/T_Choithrams_Sons_logo-removebg-preview.png';
import MediaClient9 from '../Assets/Clients/jaleel-holdings-logo-removebg-preview.png';
import MediaClient10 from '../Assets/Clients/kpmg_logo-removebg-preview.png';
import MediaClient11 from '../Assets/Clients/kwc-logo-removebg-preview.png';

import strategy from '../Assets/Service/Strategic Planning and Advisory.webp';
import endtoend from '../Assets/Service/End to end.webp';
import management from '../Assets/Service/Change.webp';
import integration from '../Assets/Service/Integration.webp';
import custom from '../Assets/Service/Customize.webp';
import  analytics from '../Assets/Service/Analytical.webp';
import support from '../Assets/Service/Continuous.webp';

import ERP from '../Assets/Products/ERP.webp';
import sap from '../Assets/Products/BTP.webp';
import fact from '../Assets/Products/success.webp';
import sales from '../Assets/Products/change.webp';
import offer from '../Assets/Products/integration.webp';

import slider1 from '../Assets/1.png';
import slider2 from '../Assets/2.png';
import slider3 from '../Assets/3.png';
import slider4 from '../Assets/4.png';

import TestImage1 from '../Assets/testi_avatar01.png';
import TestImage2 from '../Assets/testi_avatar02.png';
import TestImage3 from '../Assets/testi_avatar03.png';
import TestImage4 from '../Assets/testi_avatar04.png';


import manufact from '../Assets/manufacturing.png';
import retail from '../Assets/retail.png';
import pharma from '../Assets/Pharma.png';
import real from '../Assets/Real estate.png';
import tele from '../Assets/Tele.png';


    const ServicesSlides = [
    {
    title: 'Strategic Planning and Advisory',
    content:`Conducting in-depth analysis to align SAP SuccessFactors implementation with your organization's strategic goals.
    Providing strategic advisory services for effective utilization of SuccessFactors modules to drive business outcomes.`,
    redirect:'advisory',
    },
    {
    title: 'End-to-End Implementation Services',
    content:`Expert deployment of SAP SuccessFactors modules, ensuring a seamless and efficient transition from legacy systems.
    Customizing configurations to meet the unique needs of each organization, maximizing the platform's potential.`,
    redirect:'implementation',
    },
    {
    title: 'Change Management and Training',
    content:`Developing comprehensive change management strategies to facilitate a smooth transition for employees.
    Conducting tailored training programs to empower your workforce with the skills needed to maximize the benefits of SAP SuccessFactors.`,   
     redirect:'management', 
},
    {
    title: 'Integration and Data Migration',
    content:`Seamless integration of SAP SuccessFactors with other enterprise systems for cohesive data flow.
    Migrating data from legacy systems to SAP SuccessFactors while ensuring data integrity and security.`,
redirect:'integration'    
},
    {
    title: 'Customization and Enhancements',
    content:`Tailoring SAP SuccessFactors solutions to accommodate specific organizational requirements and industry nuances.
    Implementing enhancements to continuously optimize the platform based on evolving business needs.`,
    redirect:'customization'   
},
    {
    title: 'Analytics and Reporting',
    content:`Leveraging SAP SuccessFactors analytics to provide actionable insights for informed decision-making.
    Developing customized reports and dashboards to monitor key HR metrics and trends.`,
redirect:'analytics'    
},
    {
    title: 'Continuous Support and Maintenance',
    content:`Proactive support services to address issues promptly and ensure the stability of the SAP SuccessFactors environment.
    Regular system maintenance and updates to keep the platform aligned with the latest features and security standards.`,
redirect:'support'    
},
    ]


    const ServicesItems = [
        {
        title: 'Strategic Planning and Advisory',
        content:`Conducting in-depth analysis to align SAP SuccessFactors implementation with your organization's strategic goals;
        Providing strategic advisory services for effective utilization of SuccessFactors modules to drive business outcomes.`,
        image:strategy,
        redirect:'advisory',
        },
        {
        title: 'End-to-End Implementation Services',
        content:`Expert deployment of SAP SuccessFactors modules, ensuring a seamless and efficient transition from legacy systems;
        Customizing configurations to meet the unique needs of each organization, maximizing the platform's potential.`,
        image:endtoend,
        redirect:'implementation',
        },
        {
        title: 'Change Management and Training',
        content:`Developing comprehensive change management strategies to facilitate a smooth transition for employees;
        Conducting tailored training programs to empower your workforce with the skills needed to maximize the benefits of SAP SuccessFactors.`,    
        image:management,
        redirect:'management', 
    },
        {
        title: 'Integration and Data Migration',
        content:`Seamless integration of SAP SuccessFactors with other enterprise systems for cohesive data flow;
        Migrating data from legacy systems to SAP SuccessFactors while ensuring data integrity and security.`,
        image:integration,
        redirect:'integration'  
        },
        {
        title: 'Customization and Enhancements',
        content:`Tailoring SAP SuccessFactors solutions to accommodate specific organizational requirements and industry nuances;
        Implementing enhancements to continuously optimize the platform based on evolving business needs.`,
        image:custom,
        redirect:'customization'  
        },
        {
        title: 'Analytics and Reporting',
        content:`Leveraging SAP SuccessFactors analytics to provide actionable insights for informed decision-making;
        Developing customized reports and dashboards to monitor key HR metrics and trends.`,
        image:analytics,
        redirect:'analytics' 
        },
        {
        title: 'Continuous Support and Maintenance',
        content:`Proactive support services to address issues promptly and ensure the stability of the SAP SuccessFactors environment;
        Regular system maintenance and updates to keep the platform aligned with the latest features and security standards.`,
        image:support,
        redirect:'support'  
        },
        ]

    const ProdcutsSlide = [
    {
    title:'ERP Products',
    content:`SAP ECC & SAP S4 HANA ;  Technical`,
    },
    {
    title:'SAP BTP',
    content:`App Development ; Automation  ; Integration  ; Data and Analytics ; AI`,
    },
    {
    title:'SAP Success Factors',
    content:`Employee Central ; EC Payroll ; Recruitment and Onboarding`,
    },
    {
    title:'Sales Force (Technical and Functional)',
    content:`CRM ; Real Estate`,
    },
    {
    title:'Integration Offerings',
    content:`SAP Process Orchestration ; SAP BTP Integration Suite (Cloud Platform Integration, Edge Integration Cell, API Management, SAP Integration Advisor) ;  DellBoomi ; MuleSoft ; IBM ACE ; IBM API Connect`,
    },
    ]

    const ProdcutsItems = [
        {
        title:'ERP Products',
        productsList:`SAP ECC & SAP S4 HANA ; Functional Modules - SAP Material Management, SAP IS Retails, SAP Sales and Distribution, SAP Finance & controlling, SAP Project Systems ; 
        Technical Modules - SAP ABAP, SAP BASIS, SAP Process Orchestration`,
        image:ERP,
        },
        {
        title:'SAP BTP',
        productsList: `App Dev - SAP Build Apps, SAP Build Work Zone, SAP BTP ABAP ;
        Automation - SAP Build Process Automation, SAP Task Center;
        Integration - SAP Integration Suite, SAP Extension Suite;
        Data and Analytics - SAP Analytics Cloud, SAP Data Sphere;
        AI - SAP AI Core, SAP AI Launchpad, SAP AI Business Services;`,
        image:sap,
        },
        {
        title:'SAP Success Factors',
        productsList:`Employee Central ; EC Payroll ; Recruitment and Onboarding`,
        image:fact,
        },
        {
        title:'Sales Force (Technical and Functional)',
        productsList:`CRM ; Real Estate`,
        image:sales,
        },
        {
        title:'Integration Offerings',
        productsList:`SAP Process Orchestration ; SAP BTP Integration Suite (Cloud Platform Integration, Edge Integration Cell, API Management, SAP Integration Advisor) ; DellBoomi ; MuleSoft ; IBM ACE ; IBM API Connect`,
        image:offer,
        },
        ]

        const testimonialItems = [
        {
        title:'Robert De Niro',
        content:'The customer support at ImplementIT is top-notch. Every time I had a question or issue, the team was quick to respond and provided excellent assistance. I appreciate the dedication to customer satisfaction.',
        image:TestImage1,
        },
        {
        title:'Keanu Reeves',
        content:'I appreciate the collaborative approach of the team at ImplementIT. They took the time to understand our unique needs and worked closely with us to achieve our goals.',
        image:TestImage2,
        },
        {
        title:'Robert Downey Jr',
        content:'I appreciate the collaborative approach of the team at ImplementIT. They took the time to understand our unique needs and worked closely with us to achieve our goals.',
        image:TestImage3,
        },
        {
        title:'Scarlett Johansson',
        content:'The customer support at ImplementIT is top-notch. Every time I had a question or issue, the team was quick to respond and provided excellent assistance. I appreciate the dedication to customer satisfaction.',
        image:TestImage4,
        },
        ]


    const mediaClient = [
        {
            image: MediaClient1,
        },
        {
            image:  MediaClient2,
        },
        {
            image: MediaClient3,
        },
        {
            image: MediaClient4,
        },
        {
            image:  MediaClient5,
        },
        {
            image: MediaClient6,
        },
        {
            image:  MediaClient7,
        },
        {
            image:  MediaClient8,
        },
        {
            image:  MediaClient9,
        },
        {
            image:  MediaClient10,
        },
        {
            image:  MediaClient11,
        },
    ]


    const SliderImages = [
        {
            images:slider1,
        },
        {
            images:slider2,
        },
        {
            images:slider3
        },
        {
            images:slider4
        },
    ]

    const aboutContentList = [
        {
            item:'Strategic Planning and Advisory',
        },
        {
            item:'End-to-End Implementation Services',
        },
        {
            item:'Change Management and Training',
        },
        {
            item:'Integration and Data Migration',
        },
        {
            item:'Customization and Enhancements',
        },
        {
            item:'Continuous Support and Maintenance',
        },
    ]

    const AboutValues = [
    {
    value:'Client Value: To surpass client expectations consistently'
    },
    {
    value:'Integrity and Transparency: To be ethical, sincere and transparent in all our transactions.'
    },
    {
    value:'Excellence: To constantly improve ourselves, our teams, our services and products to become the best ERP/SAP service provider.'
    },
    {
    value:'Employee Empowerment: provide healthy environment to the employees enabling them to grow beyond expectations.'
    },
    ]

    const domains = [
        {
            name:'Retail',
            icon:retail
        },
        {
            name:'Manufacturing',
            icon:manufact,
        },
        {
            name:'Pharma',
            icon:pharma,
        },
        {
            name:'Telecom',
            icon:tele
        },
        {
            name:'Real Estate',
            icon:real
        },
    ]

export { ServicesSlides ,domains , SliderImages, AboutValues ,  testimonialItems , aboutContentList  , mediaClient , ProdcutsSlide , ServicesItems , ProdcutsItems}
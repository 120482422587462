import Navigation from '../Components/Navigation'
import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import Image1 from '../Assets/about.webp';
import Image2 from '../Assets/about.webp';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Footer from '../Components/Footer';
import { ServicesItems, ServicesSlides } from '../Variables/Variables';

export default function ServicePage() {


      const Services = ({ title, content, image, index}) => (
      <>
      {
      index % 2 === 0 ?
      <Grid container  position='relative'>

      <Grid py={8} px={1} className='servicegrid' bgcolor='#f0f7f7' item xs={12} sm={12} lg={6} xl={6} md={6} display='flex' justifyContent='center' alignItems='center' order={{ xs: 3, sm: 3, lg: 3 }}>
      <img src={image} alt="img" style={{ objectFit: 'contain', width: '100%', height:300 , mixBlendMode:'multiply' , borderRadius:'10px'}} />
      </Grid>

      <Grid  id={title.replace(/\s+/g, '').toLowerCase()} py={8} px={1} bgcolor='#f0f7f7' item xs={12} sm={12} lg={6} xl={6} md={6} display='flex' alignItems='center' order={{ xs: 4, sm: 4, lg: 4 }}>
      <Box px={3} display='flex' flexDirection='column' gap={3}>
      <Typography variant="h4" fontWeight={600} color='#C7854C' textAlign='center'>{title}</Typography>
      <Typography textAlign='justify' variant="subtitle1" sx={{ marginTop: 1, color: '#49484a' }}>{content}</Typography>
      </Box>
      </Grid>
      </Grid>
      :

      <Grid container position='relative' >

      <Grid  id={title.replace(/\s+/g, '').toLowerCase()} py={8} px={1} bgcolor='#dfeeee' item xs={12} sm={12} lg={6} xl={6} md={6} display='flex' alignItems='center' order={{ xs: 2, sm: 2, lg: 1 }}>
      <Box px={3} display='flex' flexDirection='column' gap={3}>
      <Typography variant="h4" fontWeight={600} color='#C7854C' textAlign='center'>{title}</Typography>
      <Typography textAlign='justify' variant="subtitle1" sx={{ marginTop: 1, color: '#49484a' }}>{content}</Typography>
      </Box>
      </Grid>

      <Grid py={8} px={1} className='servicegrid1' bgcolor='#dfeeee' item xs={12} sm={12} lg={6} xl={6} md={6} display='flex' justifyContent='center' alignItems='center' order={{ xs: 3, sm: 3, lg: 3 }}>
      <img src={image} alt="img" style={{ objectFit: 'contain', width: '100%', height: 300 , mixBlendMode:'multiply' , borderRadius:'10px'}} />
      </Grid>

      </Grid>
      }
      </>
      );


      useEffect(() => {
        const slideCategoryId = window.location.hash.substring(1);
    
        window.scrollTo({ top: 0, behavior: 'smooth' });
    
        setTimeout(() => {
          const element = document.getElementById(slideCategoryId);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0);
      }, []);

  return (
    <Box>
    <Box sx={{ backgroundColor: '#292929' }}>
        <Navigation />

        <Container>
        <Box py={5} position='relative'>
          <Grid container >
            <Grid item xs={12} md={12} lg={12} xl={12} className='servicesection1'>
              <Box display='flex' flexDirection='column' py={2}>
                <Typography variant="h4" textAlign='center' fontWeight={600} mb={3}>Services</Typography>
                <Typography textAlign='center' variant='h6'>Empowering Businesses with SAP Excellence, where Innovation and Integrity Define Our Path.</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>



          <Box position='relative'>
          {ServicesItems.map((item, index) => (
            <Services
              key={index}
              index={index}
              title={item.title}
              content={item.content}
              image={item.image}
              redirect={item.redirect}
            />
          ))}
        </Box>       

          <Footer />

        </Box>
        </Box>
  )
}

import React, { useState } from 'react';
import logo from '../Assets/ImplementIT_logo.png';
import {
  Drawer,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Collapse
} from '@mui/material';

import { Link } from 'react-router-dom'
import ResponsiveBd from './ResponsiveBd';
// import ResponsiveBd from './ResponsiveBd';
import CategoryIcon from '@mui/icons-material/Category';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import BookIcon from '@mui/icons-material/Book';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

function SideBar({ setMobileOpen, mobileOpen }) {



  const responsive = ResponsiveBd();
  const drawerWidth = 280;
    const [openOne, setOpenOne] = useState(false);
    const [openTwo, setOpenTwo] = useState(false);
    const [openThree, setOpenThree] = useState(false);
    const [openFour, setOpenFour] = useState(false);
    const [openFive, setOpenFive] = useState(false);
    const [openSix, setOpenSix] = useState(false);
    const [openSeven, setOpenSeven] = useState(false);
    const [openEight, setOpenEight] = useState(false);
    const [openNine, setOpenNine] = useState(false);
    const [openTen, setOpenTen] = useState(false);
   const [openOrder, setOpenOrder] = useState(false);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
};

const handleClickOne = () => {
    setOpenOne(!openOne);
  };

  
const handleClickTwo = () => {
    setOpenTwo(!openTwo);
  };

  const handleClickThree = () => {
    setOpenThree(!openThree);
  };

  const handleClickFour = () => {
    setOpenFour(!openFour);
  };

  const handleClickFive = () => {
    setOpenFive(!openFive);
  };
  const handleClickSix = () => {
    setOpenSix(!openSix);
  };
  const handleClickSeven = () => {
    setOpenSeven(!openSeven);
  };
  const handleClickEight = () => {
    setOpenEight(!openEight);
  };
  const handleClickNine = () => {
    setOpenNine(!openNine);
  };
const handleClickTen = () => {
setOpenTen(!openTen);
};

const handleClickStoreOrders = () =>{
setOpenOrder(!openOrder);
}


  const drawer = ( 
    
      <Box sx={{ height: '100%' }}>
      {/* Logo */}
      <Box sx={{ px: 2 }}>
      <Box sx={{ textAlign: 'center' }}>
     <img className='dashlogo' src={logo} alt="logo" 
      style={{ objectFit: 'contain', height: '115px', width: '200px', verticalAlign:'top'}} loading='lazy' />
      </Box>
      </Box>



      <Link to="enquirydata" className={" text-nowrap text-decoration-none fs-5 "} style={{textDecoration:'none'}}>
        <ListItem button onClick={handleDrawerToggle}  sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: '#fff' }}>
        <ListItemIcon sx={{ px: 2, color: '#fff' }}><DashboardIcon sx={{color:'#fff'}} /></ListItemIcon>
        <ListItemText>
        <Typography sx={{ fontWeight: 500 , fontSize:'19px' }}>Enquiry Data</Typography>
        </ListItemText>
        </ListItem>
        </Link>
  
  </Box>)
  return (
    <Box>
      <Drawer
        open={mobileOpen}
        variant={responsive ? 'temporary' : 'permanent'}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: 'block',
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            bgcolor: '#010417',
            color: '#fff'

          },
        }}
        anchor="left"
      >
        {drawer}
      </Drawer>
    </Box>
  )
}

export default SideBar;
import Navigation from '../Components/Navigation'
import React, { useEffect, useRef, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, Container, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import Footer from '../Components/Footer';
import Image1 from '../Assets/about.webp';
import Image2 from '../Assets/about.webp';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useNavigate } from 'react-router-dom';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import CustomTextField from '../Components/CustomTextField';
import ReCAPTCHA from "react-google-recaptcha";
import SnackBar from '../Components/Snackbar';
import { add_query } from '../API/API_Service';
import axios from 'axios';

export default function ContactPage() {

  const [expandedPanels, setExpandedPanels] = useState([]);
  const userType = localStorage.getItem('BazaarUserType');
  const BazaarUserAuth = localStorage.getItem('BazaarUserAuth')
  const [errorMsg7, setErrorMsg7] = useState('');
  const [errMsg , setErrMsg] = useState('');
  const [Error7, setError7] = useState(false);
  const [productionHouse, setProductionHouse] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [Name, setName] = useState('');
  const [subject , setSubject] = useState('');
  const [QueryMsg, setQueryMsg] = useState('');
  const [formData , setFormData] = useState([])
  const captchaRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();

  const validateInput = () =>{
    if(email !== ''){
    if (/\S+@\S+\.\S+/.test(email)) {
      setErrorMsg7("");
      setError7(false);
      } else {
      setError7(true);
      setErrorMsg7("Invalid Email Address");
      }
    }
    else{
      setError7(true);
      setErrorMsg7("Email Address Cannot Be Empty");
    }
  }

  const navigate = useNavigate();

  useEffect(() => {
    const slideCategoryId = window.location.hash.substring(1);

    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  }, []);

  const [captchaValue, setCaptchaValue] = useState('');
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);



  const onSubmit = (event) =>{
    event.preventDefault();
    const captchaValue =  captchaRef.current.getValue();
    if(Name !== '' && email !== '' && number !== '' && subject !== '' ){
    if(captchaValue){
    const sendData = new FormData()
    sendData.append('names',Name);
    sendData.append('email', email);
    sendData.append('mobile', number);
    sendData.append('subjects', subject);
    sendData.append('messages', QueryMsg);
    axios({
    method: 'POST',
    url: add_query,
    data:sendData
    }).then(res => {
    if (!res.data.error) {
    setMessage(res.data.message)
    setOpen(true)
    setStatus(true)
    setColor(true)
    setName('')
    setEmail('')
    setQueryMsg('')
    setNumber('');
    setSubject('');
    captchaRef.current.reset();
    } else {
    setOpen(true)
    setStatus(false)
    setColor(false)
    setMessage(res.data.message)
    }
    }).catch(err => {
    alert('Oops something went wrong ' + err)
    });
    }
    else{
    setOpen(true)
    setStatus(false)
    setColor(false)
    setMessage("Please verify the reCAPTCHA!");
    captchaRef.current.reset();
    }
    }
  else{
    setOpen(true)
    setStatus(false)
    setColor(false)
    setMessage('Please Fill all the Fields')
  }
}
  


  return (
    <Box>
          <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
    <Box sx={{ backgroundColor: '#292929' }}>
        <Navigation />

        <Container>
        <Box py={3} position='relative'>
          <Grid container >
            <Grid item xs={12} md={12} lg={12} xl={12} className='contactsection1'>
              <Box display='flex' flexDirection='column' py={2}>
                <Typography variant="h4" textAlign='center' fontWeight={600} mb={3}>Contact Us</Typography>
                <Typography textAlign='center' variant='h6'>We can help. Our team of experts is on hand to answer your questions.</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Box bgcolor='#010417'>
        <Box p={3} id='contactform'>
          
        <Box py={{lg:2 , md:2 , sm:1 , xs:0.5}} mb={{lg:2 , md:2 , sm:1 , xs:1}} textAlign='center'>
      <Typography variant='h3' fontWeight={550}>
        Enquiry Form</Typography>
        <Typography textAlign='center' variant='h6'>Want to get in touch? We'd love to hear from you. Here's how you can reach us.</Typography>
      </Box>

       <Grid container mt={2}  justifyContent='space-between' alignContent='center' alignItems='center'>
      <Grid item xs={12} sm={12} md={12} display='flex' justifyContent='center'>

      <Box component={Card} bgcolor='#dfeeee' textAlign='left' p={2} borderRadius='20px' maxWidth={600}>
      <Box p={4}>
      <Grid container spacing={3} >
      <Grid item xs={12}>
      <Box>
      <Typography color='#262626'>Name</Typography>
      <CustomTextField
      onChange={setName}
      value={Name}
      validationRegex={/^[A-Za-z]+$/}
      errorMessage={"Forbidden character: %<>$'\""}
      />
      </Box>
      </Grid>
      <Grid item xs={12}>
      <Box>
      <Typography color='#262626'>Contact Number</Typography>
      <CustomTextField
      value={number}
      onChange={setNumber}
      validationRegex={/^[0-9]+$/}
      errorMessage={"Enter a Numeric Value"}
      />
      </Box>
      </Grid>
      <Grid item xs={12}>
      <Box>
      <Typography color='#262626'>Email Id</Typography>
      <TextField
      name="Email"
      fullWidth
      size='small'
      value={email}
      helperText={errorMsg7}
      error={Error7}
      onChange={(e) => {
      const newValue = e.target.value;
      setEmail(newValue);
      }}
      onBlur={validateInput}
      />
      </Box>
      </Grid>
      <Grid item xs={12}>
      <Box>
      <Typography color='#262626'>Subject</Typography>
      <CustomTextField
      onChange={setSubject}
      value={subject}
      validationRegex={/^[A-Za-z]+$/}
      errorMessage={"Forbidden character: %<>$'\""}
      multiline={true}
      rows={2}
      />
      </Box>
      </Grid>
      <Grid item xs={12}>
      <Box>
      <Typography color='#262626'>Your Message</Typography>
      <CustomTextField
      onChange={setQueryMsg}
      value={QueryMsg}
      validationRegex={''}
      errorMessage={""}
      multiline={true}
      rows={4}
      />
      </Box>
      </Grid>
      <Grid item xs={12}>
      <ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_SITE_KEY} />
       </Grid>
      <Grid item xs={12}>
      <Box>
      <Button fullWidth onClick={onSubmit} variant='contained' sx={{bgcolor:'#46344E', color:'#fff' , ':hover':{bgcolor:'#46344E', color:'#fff' ,}}} >Submit</Button>
      </Box>
      </Grid>
      </Grid>
      </Box>
      </Box>


      </Grid>
      </Grid>
      </Box>
      </Box>


      <Box position='relative'  backgroundColor='#292929' py={4}>
        <Box p={4}>
      <Grid container mt={2}  justifyContent='space-between' alignContent='center' alignItems='center'>
      <Grid item xs={12} sm={12} md={5} lg={5} position='relative' display='flex' flexDirection='column' justifyContent='center'>
        <Stack spacing={1} textAlign='left'>
        <Typography  variant='p' fontWeight={700} fontSize='40px' textTransform='capitalize' color='#fff'>Contact Details</Typography>
        <Box display='flex' flexDirection='column' gap={2} p={2}>
        <Typography variant='p' color='#798DA3' width='50%' sx={{wordWrap:'break-word' , wordBreak:'break-word'}}>
         <strong>Addresses : </strong>
         <br/>
         F503, Aparna Hillpark Silver Oaks, ChandaNagar, Hyderabad
         <br /> <br />
         5-416, Main Road, Komarole,Prakasam District, AndhraPradesh
         </Typography>
        <Typography variant='p' color='#798DA3' > <strong>Phone :</strong> 9876543210</Typography>
        <Typography variant='p' color='#798DA3' > <strong>Email :</strong> support@ImplementIT.in</Typography>
        <Box py={1}>
        <a style={{ textDecoration: 'none', cursor: 'pointer' ,fontWeight:600 ,  color: '#C7854C' }} href={'/'} target="_blank" rel="noopener">View on Google Maps</a>
        </Box>
        </Box>
        </Stack>
        </Grid>


      <Grid item xs={12} sm={12} md={7} lg={7}display='flex' justifyContent='center'>
      <iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=5-416,%20Main%20Road,%20Komarole,%20,%20AndhraPradesh%20Prakasam%20District+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></Grid>
      </Grid>
      </Box>
      </Box>

      <Box position='relative'  backgroundColor='#010417'>
      <Stack spacing={3} p={6} className='contactgrid3'  textAlign='center'>
      <Typography variant='h3'>Interested in our latest chapter?</Typography>
      <Box>
      <Button onClick={()=>navigate('/products')} variant='outlined' sx={{ color:'#fff' ,  borderRadius:'10px' , borderColor:'#fff'}}>Learn More About our Products <DoubleArrowIcon sx={{verticalAlign:'middle' , ml:2}} /> </Button>
      </Box>
      </Stack>
      </Box>

       <Footer />


        </Box>
        </Box>
  )
}

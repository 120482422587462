import Navigation from '../Components/Navigation'
import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography, Container, Grid, Card, CardMedia, CardContent, Button, Link, List, ListItem, ListItemIcon, ListItemText, Divider, Accordion, AccordionActions, AccordionDetails, AccordionSummary, Stack, } from '@mui/material'
import Footer from '../Components/Footer';
import Image1 from '../Assets/about.webp';
import Image2 from '../Assets/about.webp';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useNavigate } from 'react-router-dom';
import { AboutValues, aboutContentList } from '../Variables/Variables';
import FiberManualRecordSharpIcon from '@mui/icons-material/FiberManualRecordSharp';
import MUIDataTable from "mui-datatables";
import SecondFooter from '../Components/SecondFooter';
import { get_query } from '../API/API_Service';
import axios from 'axios';

export default function EnquiryData() {

    const options = {
        filterType: "dropdown",
        responsive: "scroll"
      };
  
  
      const [open, setOpen] = useState(false);
      const [message, setMessage] = useState("");
      const [status, setStatus] = useState();
      const [color, setColor] = useState();
      const [data , setData] = useState([]);
      const navigate = useNavigate();
      

      const getAllData = () => {
        const sendData = new FormData()
        sendData.append('id', '');
        axios({
          method: 'POST',
          url: get_query,
          data:sendData
        }).then(res => {
          if (!res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(true)
            setColor(true)
              let temp = [];
              let dummy;
              res.data.data.map((data, index) => {
                dummy = {
                  id: index + 1,
                  names: data.names,
                  email: data.email,
                  phone: data.mobile,
                  subjects: data.subjects,
                  messages: data.messages,
                };
                temp.push(dummy);
                return null;
              });
              setData(temp);
          } else {
            setOpen(true)
            setStatus(false)
            setColor(false)
            setMessage(res.data.message)
          }
        }).catch(err => {
          alert('Oops something went wrong ' + err)
        });
      }
    
      useEffect(() => {
        getAllData();
      }, []);
    
  
      const columns = [
        {
          name: "id",
          label: "S.No",
          options: {
            filter: false,
            customBodyRender: (value, meta) => {
              return meta.rowIndex + 1;
            },
          },
        },
        {
          name: "names",
          label: "Name",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                  <div>
                  <div>{value ? value : "-"}</div>
                </div>
              );
            },
          },
        },
        {
          name: "email",
          label: "Email",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div>{value ? value : "-"}</div>
                </div>
              );
            },
          },
        },
        {
            name: "phone",
            label: "Mobile",
            options: {
              customBodyRender: (value, tableMeta) => {
                return (
                  <div>
                    <div>{value ? value : "-"}</div>
                  </div>
                );
              },
            },
          },
        {
          name: "subjects",
          label: "Subjects",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div>{value ? value : "-"}</div>
                </div>
              );
            },
          },
        },
        {
          name: "messages",
          label: "Messages",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div>{value ? value : "-"}</div>
                </div>
              );
            },
          },
        },
      ];
  

  useEffect(() => {
    const slideCategoryId = window.location.hash.substring(1);

    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  }, []);

  return (
    <Box minHeight='90vh' sx={{ backgroundColor: '#292929' }}>

        <Box  textAlign='left' py={3}>
                <Container>
                <Typography variant='h5' color='#fff' fontWeight={550}>
        Enquiry Form Data</Typography>
                <Box p={3} >
                {
                data.length !== 0 &&
                <MUIDataTable
                sx={{bgcolor:'#dfeeee'}}
                title={"Queries"}
                data={data}
                columns={columns}
                options={options}
                />
                }
                </Box>
            </Container>
        </Box>


        </Box>
  )
}
